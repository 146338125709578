var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch, Emit, PropSync, } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { getVideosCRM } from '@/shared/utils/Infosets.utils';
let Bitrix24ConfigCard = class Bitrix24ConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.urlBitrix24Check = false;
        this.isPwdBitrix24 = true;
        this.openModal = false;
        this.companyFields = {
            fields: {
                'Razão Social/Nome Fantasia': true,
                CNPJ: true,
                Setor: true,
                'Cnae Primário': true,
                'Cnae Secundário': true,
                'Data de Abertura': true,
                'Faixa de Faturamento da Unidade CNPJ': true,
                'Faixa de Faturamento da Empresa': true,
                'Faixa de Funcionários da Unidade CNPJ': true,
                'Faixa de Funcionarios da Empresa': true,
                'Natureza Jurídica': true,
                Administrador: true,
                Facebook: true,
                Website: true,
                'Todos os Emails': true,
                'Todos os Telefones': true,
                'Telefones validos': true,
                Decisores: true,
                'Emails Principais': true,
                'Telefones Principais': true,
                Endereço: true,
                Situação: true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'E-mail',
            'Empresa',
            'Posição',
            'Nível de decisão',
            'Link do linkedIn',
        ];
    }
    get linkVideo() {
        return getVideosCRM('bitrix24');
    }
    tooltipText() {
        const message = `
            <li>Exportar até 10000 empresas sem decisores;</li>
            <li>Exportar até 10000 decisores com no máximo 10000 empresas;</li>
            <li>Possuir permissões habilitadas no token para integração com o Bitrix24.</li>
            <li>Gerar corretamente o token no Bitrix24.</li>
            `;
        return message;
    }
    checkUrlBitrix24(url) {
        mixpanelTracking('integrações: Bitrix24 foi integrado');
        return url;
    }
    onSetUrlStorage(curr) {
        if (curr) {
            this.urlBitrix24 = curr;
            this.urlBitrix24Check = true;
        }
        else {
            this.urlBitrix24 = '';
            this.urlBitrix24Check = false;
        }
    }
    setNewUrlBitrix24() {
        this.urlBitrix24 = '';
        this.urlBitrix24Check = false;
        localStorage.removeItem('urlBitrix24');
        mixpanelTracking('integrações: Token Bitrix24 alterado');
    }
    mounted() {
        mixpanelTracking('integrações: Bitrix24 foi aberto');
    }
};
__decorate([
    PropSync('url', { default: '' })
], Bitrix24ConfigCard.prototype, "urlBitrix24", void 0);
__decorate([
    Prop({ default: false })
], Bitrix24ConfigCard.prototype, "loading", void 0);
__decorate([
    Prop({ default: '' })
], Bitrix24ConfigCard.prototype, "urlStorage", void 0);
__decorate([
    Emit('checkUrlBitrix24')
], Bitrix24ConfigCard.prototype, "checkUrlBitrix24", null);
__decorate([
    Watch('urlStorage')
], Bitrix24ConfigCard.prototype, "onSetUrlStorage", null);
Bitrix24ConfigCard = __decorate([
    Component({
        name: 'Bitrix24ConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], Bitrix24ConfigCard);
export default Bitrix24ConfigCard;
