var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch, Emit, PropSync, } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
let PloomesConfigCard = class PloomesConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.keyPloomesCheck = false;
        this.isPwdPloomes = true;
        this.openModal = false;
        this.companyFields = {
            fields: {
                fields: {
                    'Razão Social': true,
                    CEP: true,
                    CNPJ: true,
                    Bairro: true,
                    'CNAE Principal': true,
                    'CNAE Secundário': true,
                    'Todos os Emails': true,
                    'Endereço na Web': true,
                    'Url do Facebook': true,
                    Rua: true,
                    'Dados dos Decisores': true,
                    'Outras Informações': true,
                    Decisores: true,
                    'Emails Principais': true,
                    'Telefones Principais': true,
                },
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'Cargo',
            'Departamento',
            'E-mail',
            'Link do linkedIn',
        ];
    }
    tooltipText() {
        const message = `<li>Exportar até 10.000 empresas sem ou com poucos decisores;</li>
            <li>Possuir permissões habilitadas para integração com o Ploomes;</li>
            <li>Verificar se o plano da sua conta seja Ploomes Básico e tenham habilitados os serviços sob demanda de Acesso a Api e Integrações.</li>
            `;
        return message;
    }
    checkTokenPloomes(token) {
        return token;
    }
    onSetTokenStorage(curr, old) {
        if (curr) {
            this.keyPloomes = curr;
            this.keyPloomesCheck = true;
        }
        else {
            this.keyPloomes = '';
            this.keyPloomesCheck = false;
        }
    }
    setNewTokenPloomes() {
        this.keyPloomes = '';
        this.keyPloomesCheck = false;
        localStorage.removeItem('tokenPloomes');
    }
};
__decorate([
    PropSync('token', { default: '' })
], PloomesConfigCard.prototype, "keyPloomes", void 0);
__decorate([
    Prop({ default: false })
], PloomesConfigCard.prototype, "loading", void 0);
__decorate([
    Prop({ default: '' })
], PloomesConfigCard.prototype, "tokenStorage", void 0);
__decorate([
    Emit('checkTokenPloomes')
], PloomesConfigCard.prototype, "checkTokenPloomes", null);
__decorate([
    Watch('tokenStorage')
], PloomesConfigCard.prototype, "onSetTokenStorage", null);
PloomesConfigCard = __decorate([
    Component({
        name: 'PloomesConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], PloomesConfigCard);
export default PloomesConfigCard;
