var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
let ZohoConfigCard = class ZohoConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.urlZoho = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.ALL,ZohoCRM.modules.leads.ALL,ZohoCRM.settings.modules.READ&client_id=${this.zohoClientId}&response_type=code&access_type=online&redirect_uri=${this.zohoRedirectUrl}`;
        this.disabledField = false;
        this.keyZohoCheck = false;
        this.isPwd = true;
        this.openModal = false;
        this.companyFields = {
            fields: {
                'Razão Social/Nome Fantasia': true,
                CNPJ: true,
                Setor: true,
                Endereço: true,
                'Cnae Primário': true,
                'Cnae Secundário': true,
                'Data de Abertura': true,
                'Faixa de Faturamento da Unidade CNPJ': true,
                'Faixa de Faturamento da Empresa': true,
                'Faixa de Funcionários da Unidade CNPJ': true,
                'Faixa de Funcionarios da Empresa': true,
                'Natureza Jurídica': true,
                Administrador: true,
                Facebook: true,
                Website: true,
                'Todos os Emails': true,
                'Todos os Telefones': true,
                Decisores: true,
                Situação: true,
                'Nome do Administrador': true,
                'Capital Social': true,
                'Emails Principais': true,
                'Telefones Principais': true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'E-mail',
            'Cargo',
            'Endereço',
            'Departamento',
            'Nível de decisão',
        ];
    }
    handleLinkVideo() {
        window.open('https://share.vidyard.com/watch/YDFq8SumNj2BwiDiuAJwmU', '_blank');
    }
    tooltipText() {
        const message = `<li>Exportar até 10.000 empresas sem ou com poucos decisores;</li>
            <li>Possuir permissões habilitadas para integração com o Zoho</li>
            `;
        return message;
    }
    toggleDisable(value) {
        return value;
    }
    checkTokensZoho(accessKeyZoho, integrationKeyZoho) {
        return { accessKeyZoho, integrationKeyZoho };
    }
    handleBtnClick() {
        window.open(this.urlZoho, 'popup', 'height=600,width=400');
        return false;
    }
    get zohoRedirectUrl() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? 'https://dev.speedio.com.br/crm/zoho/callback'
            : hostname.match('test')
                ? 'https://staging.speedio.com.br/crm/zoho/callback'
                : hostname.match('app')
                    ? 'https://app.speedio.com.br/crm/zoho/callback'
                    : hostname.match('staging')
                        ? 'https://staging.speedio.com.br/crm/zoho/callback'
                        : 'http://localhost:8080/crm/zoho/callback';
    }
    get zohoClientId() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? '1000.JORTZZ0FYUK4MYJWH213HYZQZBU0QT'
            : hostname.match('test')
                ? '1000.UDTISKVCTIPDIIOVO1PICD5KMPTUUE'
                : hostname.match('app')
                    ? '1000.ST29HORWYJ64BLYOE8H8OP7F7RHUEU'
                    : hostname.match('staging')
                        ? '1000.UDTISKVCTIPDIIOVO1PICD5KMPTUUE'
                        : '1000.N6HPK79ZLI6SK8VG26EXVMPSAVUYLI';
    }
};
__decorate([
    Prop({ default: false })
], ZohoConfigCard.prototype, "loading", void 0);
__decorate([
    Emit('toggleDisable')
], ZohoConfigCard.prototype, "toggleDisable", null);
__decorate([
    Emit('checkTokensZoho')
], ZohoConfigCard.prototype, "checkTokensZoho", null);
ZohoConfigCard = __decorate([
    Component({
        name: 'ZohoConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], ZohoConfigCard);
export default ZohoConfigCard;
