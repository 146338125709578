var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { getVideosCRM } from '@/shared/utils/Infosets.utils';
let HubSpotConfigCard = class HubSpotConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.urlHubspot = `https://app.hubspot.com/oauth/authorize?client_id=${this.hubSpotClientId}&redirect_uri=${this.hubSpotRedirectUrl}&scope=oauth%20integration-sync%20crm.objects.contacts.read%20crm.import%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write`;
        this.openModal = false;
        this.companyFields = {
            fields: {
                'Razão Social': true,
                'Nome Fantasia': true,
                Estado: true,
                CNPJ: true,
                Cidade: true,
                CEP: true,
                Endereço: true,
                País: true,
                Website: true,
                Setor: true,
                Origem: true,
                'Data de Abertura': true,
                'Capital Social': true,
                'Faixa de Faturamento da Unidade CNPJ': true,
                'Faixa de Funcionários da Unidade CNPJ': true,
                'Todos os Telefones': true,
                'Todos os Emails': true,
                'Emails Válidos': true,
                'Página da Empresa no Facebook': true,
                'Outras Informações': true,
                Decisores: true,
                'Telefones Principais': true,
                'Emails Principais': true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'Empresa',
            'Nível de decisão',
            'E-mail',
            'Link do linkedIn',
        ];
    }
    get linkVideo() {
        return getVideosCRM('hubspot');
    }
    tooltipText() {
        const message = `
            <li>Exportar até 10.000 empresas sem ou com poucos decisores;</li>
            <li>Possuir permissões habilitadas para integração com o Hubspot;</li>
            <li>Verificar se o plano da sua conta seja Professional ou Enterprise.</li>
            `;
        return message;
    }
    handleBtnClick() {
        window.open(this.urlHubspot, 'popup', 'height=600,width=400');
        mixpanelTracking('integrações: Configuração com Hubspot iniciada');
        return false;
    }
    get hubSpotRedirectUrl() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? 'https://dev.speedio.com.br/crm/hubspot/callback'
            : hostname.match('test')
                ? 'https://staging.speedio.com.br/crm/hubspot/callback'
                : hostname.match('app')
                    ? 'https://app.speedio.com.br/crm/hubspot/callback'
                    : hostname.match('staging')
                        ? 'https://staging.speedio.com.br/crm/hubspot/callback'
                        : 'http://localhost:8080/crm/hubspot/callback';
    }
    get hubSpotClientId() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? '988214e3-1f87-4f65-9569-64978ffce662'
            : hostname.match('test')
                ? 'edd528f3-3875-4bea-a7ef-5b3cf3310629'
                : hostname.match('app')
                    ? '0c6db7ea-9782-4f3e-bb75-8d21ae8e89ca'
                    : hostname.match('staging')
                        ? 'edd528f3-3875-4bea-a7ef-5b3cf3310629'
                        : '8a07d258-e4de-4631-bfa0-75a9a1323a17';
    }
    mounted() {
        mixpanelTracking('integrações: Hubspot foi aberto');
    }
};
HubSpotConfigCard = __decorate([
    Component({
        name: 'HubSpotConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], HubSpotConfigCard);
export default HubSpotConfigCard;
