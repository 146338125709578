var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Prop, Vue, Component, Emit } from 'vue-property-decorator';
let CancelSubscriptionModalFooter = class CancelSubscriptionModalFooter extends Vue {
    closeModal() {
        return;
    }
    cancelSubscription(superlogicaId) {
        return;
    }
};
__decorate([
    Prop({ default: 0 })
], CancelSubscriptionModalFooter.prototype, "superlogicaId", void 0);
__decorate([
    Prop({ default: false })
], CancelSubscriptionModalFooter.prototype, "loadingCancelButton", void 0);
__decorate([
    Prop({ default: {} })
], CancelSubscriptionModalFooter.prototype, "subscription", void 0);
__decorate([
    Emit('closeModal')
], CancelSubscriptionModalFooter.prototype, "closeModal", null);
__decorate([
    Emit('cancelSubscription')
], CancelSubscriptionModalFooter.prototype, "cancelSubscription", null);
CancelSubscriptionModalFooter = __decorate([
    Component({
        name: 'CancelSubscriptionModalFooter',
        components: {},
    })
], CancelSubscriptionModalFooter);
export default CancelSubscriptionModalFooter;
