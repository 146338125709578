var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Prop, Vue, Component } from 'vue-property-decorator';
import { QuasarDateLocaleOptions, } from '@/shared/utils/helpers';
let CancelSubscriptionModalMain = class CancelSubscriptionModalMain extends Vue {
    get getDate() {
        const date = this.subscription.speculative_expiration_date.split('T');
        let [year, month, day] = date[0].split('-');
        month = QuasarDateLocaleOptions.months[Number(month) - 1];
        return { day, month, year };
    }
};
__decorate([
    Prop({ default: {} })
], CancelSubscriptionModalMain.prototype, "subscription", void 0);
CancelSubscriptionModalMain = __decorate([
    Component({
        name: 'CancelSubscriptionModalMain',
        components: {},
    })
], CancelSubscriptionModalMain);
export default CancelSubscriptionModalMain;
