var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Emit, Prop, PropSync, Vue, Component, } from 'vue-property-decorator';
import Modal from '../Modal.vue';
import CancelSubscriptionModalHeader from './Modal/CancelSubscription/CancelSubscriptionModalHeader.vue';
import CancelSubscriptionModalMain from './Modal/CancelSubscription/CancelSubscriptionModalMain.vue';
import CancelSubscriptionModalFooter from './Modal/CancelSubscription/CancelSubscriptionModalFooter.vue';
let CancelSubscriptionModal = class CancelSubscriptionModal extends Vue {
    cancelSubscription(superlogica_id) {
        return;
    }
    closeModal() {
        return;
    }
};
__decorate([
    Prop({ default: () => { } })
], CancelSubscriptionModal.prototype, "subscription", void 0);
__decorate([
    PropSync('open', { default: false })
], CancelSubscriptionModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: false })
], CancelSubscriptionModal.prototype, "loadingCancelButton", void 0);
__decorate([
    Emit('cancelSubscription')
], CancelSubscriptionModal.prototype, "cancelSubscription", null);
__decorate([
    Emit('closeModal')
], CancelSubscriptionModal.prototype, "closeModal", null);
CancelSubscriptionModal = __decorate([
    Component({
        name: 'CancelSubscriptionModal',
        components: {
            Modal,
            CancelSubscriptionModalHeader,
            CancelSubscriptionModalMain,
            CancelSubscriptionModalFooter,
        },
    })
], CancelSubscriptionModal);
export default CancelSubscriptionModal;
