var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import RdStationConfigCard from '../components/RdStationConfigCard.vue';
import { connect } from '@/overmind';
import { json } from 'overmind';
let RdStationContainer = class RdStationContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.keyRdStation = json(this.tokenRDStationSaved);
        this.codeRdStation = '';
        this.code = '';
        this.loading = false;
        this.tokenStorage = '';
        this.keyRdStationCheck = false;
    }
    showNotify(message, type) {
        this.$q.notify({
            message: message,
            type: type,
        });
    }
    mounted() {
        this.tokenStorage = localStorage.getItem('tokenRdSTation');
    }
    onCodeRdStationChanged(newCode, oldCode) {
        if (newCode && newCode !== oldCode) {
            this.getTokenRDStation();
        }
    }
    checkStatusRdStation(token) {
        if (token) {
            this.keyRdStationCheck = true;
            this.showNotify('Seu token do RD Station foi salvo com sucesso. Agora você pode exportar para o RD Station!', 'exportation-success');
        }
    }
    setNewTokenRdStation() {
        localStorage.removeItem('tokenRdSTation');
    }
    async getTokenRDStation() {
        // @ts-ignore
        await this.actions.getTokenRDStation({
            client_id: this.rdStationClientIdUrl,
            client_secret: this.rdStationClientSecretUrl,
            code: this.code,
        });
    }
    async setTokenRDStation() {
        this.loading = true;
        // @ts-ignore TS2349: property inexistent
        const isValid = await this.actions.setRDStationToken({
            token: this.keyRdStation,
        });
        this.checkStatusRdStation(isValid);
        if (isValid) {
            //@ts-ignore
            await this.actions.saveCrmAutoplay({
                crm: 'rdstation',
                token: this.keyRdStation,
            });
            //@ts-ignore
            await this.actions.getCrmAutoplay('rdstation');
        }
        this.loading = false;
    }
    get rdStationClientIdUrl() {
        return '3MVG9kBt168mda_8emrcrHB1P70NkwEru9oZsJRamCrxR2T79Tqu0xLl7cBPzm3un.GE6ZA_T8.18hZ9O32tt';
    }
    get rdStationClientSecretUrl() {
        return 'CFEADEB99321CAF49BFDC6B7207B01D6F289EE3023C470998DAEC834195F43D2';
    }
};
__decorate([
    Watch('codeRdStation')
], RdStationContainer.prototype, "onCodeRdStationChanged", null);
RdStationContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.users,
        tokenRDStationSaved: state.users.tokenRdSTation,
    }), {
        name: 'RDStationContainer',
        components: {
            RdStationConfigCard,
        },
    }))
], RdStationContainer);
export default RdStationContainer;
