var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { getAppExchangeBaseUrl, getApiUrl } from '@/shared/utils/helpers';
let ExportOracle = class ExportOracle extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'exportOracle';
        this.token = '';
        this.baseURL = getAppExchangeBaseUrl();
        this.url = '';
    }
    async created() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.getUserMe();
        // @ts-ignore TS2349: property inexistent
    }
    async mounted() {
        this.$gtag.pageview({ page_path: this.$route.path });
        this.token = await localStorage.getItem('auth_token_default');
        this.url = await `${this.baseURL}/app-oracle-export/?config=true&token=${this.token}&api=${getApiUrl()}`;
    }
};
ExportOracle = __decorate([
    Component(connect(({ state, effects, actions }) => ({
        state: state.users,
        actions: actions.users,
        infosetsActions: actions.infosets,
    }), {
        name: 'ExportOracle',
        components: {},
    }))
], ExportOracle);
export default ExportOracle;
