var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fit"},[_c('div',{staticClass:"row justify-between"},[_c('div',{staticClass:"col-sm-7 col-md-4"},[_c('UserHeaderContent',{attrs:{"title":_vm.title}})],1),_c('div',{staticClass:"col-sm-5 col-md-4"})]),_c('div',{staticClass:"row marginContentTop--ChangePassword"},[_c('div',{staticClass:"col-sm-12 col-md-8 col-lg-5 col-xl-4"},[_c('div',{staticClass:"column"},[(_vm.dontPassword)?_c('div',[_c('label',{staticClass:"color-password-grey-dark"},[_vm._v("Senha atual")]),_c('q-input',{ref:"actual",staticClass:"user-password-mt-2",attrs:{"rounded":"","clearable":"","outlined":"","placeholder":"Digite aqui...","bg-color":"white","type":"password","loading":_vm.loading,"disable":_vm.loading,"rules":[
              _vm.notChanged ? function (val) { return !!val || 'Campo é obrigatório'; } : null ]},model:{value:(_vm.actualPassword),callback:function ($$v) {_vm.actualPassword=$$v},expression:"actualPassword"}})],1):_vm._e(),_c('div',{staticClass:"user-password q-mt-lg"},[_c('label',{staticClass:"color-password-grey-dark"},[_vm._v("Nova senha")]),_c('q-input',{ref:"new",staticClass:"user-password-mt-2",attrs:{"rounded":"","outlined":"","clearable":"","placeholder":"Digite aqui...","bg-color":"white","type":"password","loading":_vm.loading,"disable":_vm.loading,"rules":[
              function (val) { return !!val || 'Campo é obrigatório'; },
              function (val) { return /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(
                  val
                ) ||
                'Sua senha deve ter no mínimo 12 caracteres, entre eles um número, uma letra maiúscula e um caractere especial'; } ]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('div',{staticClass:"user-password q-mt-lg"},[_c('label',{staticClass:"color-password-grey-dark"},[_vm._v("Confirmar nova senha")]),_c('q-input',{ref:"confirm",staticClass:"user-password-mt-2",attrs:{"rounded":"","clearable":"","outlined":"","placeholder":"Digite aqui...","bg-color":"white","type":"password","loading":_vm.loading,"disable":_vm.loading,"rules":[
              _vm.notChanged ? function (val) { return !!val || 'Campo é obrigatório'; } : null,
              function (val) { return val === _vm.newPassword || 'As senhas não coincidem'; } ]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('div',{staticClass:"row q-gutter-x-md user-password-mt-7"},[_c('div',{staticClass:"col-auto"},[(_vm.loading)?_c('q-btn',{staticClass:"text-capitalize input-style-user-password",attrs:{"color":"primary","rounded":"","size":"md","unelevated":"","disable":"","label":"Salvando..."}},[_c('span',{staticClass:"sp-icon-loading-white sp-sm q-ml-sm"})]):_c('q-btn',{staticClass:"text-capitalize input-style-user-password",attrs:{"id":"user-control-btn--save-password","color":"primary","rounded":"","label":"salvar","unelevated":"","size":"md","disable":_vm.saveButtonDisabled},on:{"click":_vm.confirm}})],1),_c('div',{staticClass:"col-auto"},[(_vm.loading)?_c('q-btn',{staticClass:"input-style-user-password",attrs:{"color":"white","text-color":"grey","label":"Cancelar","unelevated":"","rounded":"","size":"md","disable":""}}):_c('q-btn',{staticClass:"text-capitalize input-style-user-password",attrs:{"color":"red","text-color":"white","label":"Cancelar","unelevated":"","rounded":"","size":"md"},on:{"click":_vm.cleanForm}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }