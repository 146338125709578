var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch, PropSync, Emit, } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
import { getVideosCRM } from '@/shared/utils/Infosets.utils';
let MeetimeConfigCard = class MeetimeConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.keyMeetimeCheck = false;
        this.isPwdMeetime = true;
        this.openModal = false;
        this.companyFields = {
            fields: {
                'Razão Social': true,
                CNPJ: true,
                Origem: true,
                Website: true,
                Endereço: true,
                Facebook: true,
                Decisores: true,
                'Cnae Primário': true,
                'Todos os Telefones': true,
                'Email do tomador de decisão': true,
                'Cargo do tomador de decisão': true,
                'LinkedIn do tomador de decisão': true,
                'Faixa de Faturamento da Empresa': true,
                'Faixa de Funcionarios da Empresa': true,
                'Primeiro nome do tomador de decisão': true,
                'Nome completo do tomador de decisão': true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
    }
    get linkVideo() {
        return getVideosCRM('meetime');
    }
    checkTokenMeetime(tokenMeetime) {
        return tokenMeetime;
    }
    onTokenStorage(curr, old) {
        if (curr) {
            this.keyMeetime = curr;
            this.keyMeetimeCheck = true;
        }
        else {
            this.keyMeetime = '';
            this.keyMeetimeCheck = false;
        }
    }
    setNewTokenMeetime() {
        this.keyMeetime = '';
        this.keyMeetimeCheck = false;
        localStorage.removeItem('tokenMeetime');
    }
};
__decorate([
    PropSync('token', { default: '' })
], MeetimeConfigCard.prototype, "keyMeetime", void 0);
__decorate([
    Prop({ default: false })
], MeetimeConfigCard.prototype, "loading", void 0);
__decorate([
    Prop({ default: '' })
], MeetimeConfigCard.prototype, "tokenStorage", void 0);
__decorate([
    Emit('checkTokenMeetime')
], MeetimeConfigCard.prototype, "checkTokenMeetime", null);
__decorate([
    Watch('tokenStorage')
], MeetimeConfigCard.prototype, "onTokenStorage", null);
MeetimeConfigCard = __decorate([
    Component({
        name: 'MeetimeConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], MeetimeConfigCard);
export default MeetimeConfigCard;
