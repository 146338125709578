var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import PipedriveConfigCard from '../components/PipedriveConfigCard.vue';
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { json } from 'overmind';
let PipedriveContainer = class PipedriveContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.setTokenPipedrive = json(this.stateTokenPipedrive);
        this.tokenStorage = '';
        this.loading = false;
    }
    mounted() {
        this.tokenStorage = localStorage.getItem('tokenPipeDrive');
    }
    async checkTokenPipedrive(token) {
        //@ts-ignore TS2349: property inexistent
        await this.actions.getTokenPipeDrive({ token });
        //@ts-ignore
        await this.actions.saveCrmAutoplay({ crm: 'pipedrive', token: token });
        //@ts-ignore
        await this.actions.getCrmAutoplay('pipedrive');
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }
};
PipedriveContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.users,
        stateTokenPipedrive: state.users.tokenPipeDrive,
    }), {
        name: 'PipedriveContainer',
        components: {
            PipedriveConfigCard,
        },
    }))
], PipedriveContainer);
export default PipedriveContainer;
