var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import UserHeaderContent from '../components/UserControlHeaderContent.vue';
import SalesforceConfigCard from '../components/SalesforceConfigCard.vue';
import PipedriveContainer from '../containers/Pipedrive.container.vue';
import RdStationContainer from '../containers/RdStation.container.vue';
import PloomesContainer from '../containers/Ploomes.container.vue';
import HubSpotConfigCard from '../components/HubSpotConfigCard.vue';
import Bitrix24Container from '../containers/Bitrix24.container.vue';
import AgendorContainer from '../containers/Agendor.container.vue';
import PiperunContainer from '../containers/Piperun.container.vue';
import ToTvsContainer from '../containers/ToTvs.container.vue';
import FunilDeVendasContainer from '../containers/FunilDeVendas.container.vue';
import ZohoConfigCard from '../components/ZohoConfigCard.vue';
import MeetimeContainer from '../containers/Meetime.container.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let IntegratedCRMsListContainer = class IntegratedCRMsListContainer extends Vue {
    constructor() {
        super(...arguments);
        this.title = 'Integração com CRM';
        this.showMeetimeIntegration = false;
    }
    mounted() {
        this.$gtag.pageview({ page_path: this.$route.path });
        mixpanelTracking('painel do usuário: Abriu a aba Integrações com CRM');
    }
};
IntegratedCRMsListContainer = __decorate([
    Component({
        name: 'IntegratedCRMListContainer',
        components: {
            UserHeaderContent,
            SalesforceConfigCard,
            RdStationContainer,
            PipedriveContainer,
            PloomesContainer,
            HubSpotConfigCard,
            Bitrix24Container,
            AgendorContainer,
            PiperunContainer,
            ToTvsContainer,
            FunilDeVendasContainer,
            ZohoConfigCard,
            MeetimeContainer,
        },
    })
], IntegratedCRMsListContainer);
export default IntegratedCRMsListContainer;
