var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop, Emit, Watch, } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
let ToTvsConfigCard = class ToTvsConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.disabledField = false;
        this.keyToTvsCheck = false;
        this.isPwd = true;
        this.openModal = false;
        this.companyFields = {
            fields: {
                'Nome/Razão Social': true,
                'Apelido/Nome Fantasia': true,
                CNPJ: true,
                Endereço: true,
                'Data de Nascimento/Data de Fundação': true,
                'Todos os Emails': true,
                'Todos os Telefones': true,
                Facebook: true,
                Website: true,
                'Outras Informações/Observações': true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'Empresa',
            'Posição',
            'E-mail',
            'Endereço',
            'Departamento',
            'Nível de decisão',
            'Link do linkedIn',
        ];
    }
    handleLinkVideo() {
        window.open('https://share.vidyard.com/watch/r6F6JpMi9o82b2CrgL7iKy', '_blank');
    }
    tooltipText() {
        const message = `<li>Exportar até 10.000 empresas sem ou com poucos decisores;</li>
            <li>Possuir permissões habilitadas para integração com o ToTvs</li>
            `;
        return message;
    }
    toggleDisable(value) {
        return value;
    }
    checkTokensToTvs(accessKeyToTvs, integrationKeyToTvs) {
        return { accessKeyToTvs, integrationKeyToTvs };
    }
    onAccessTokenStorage(curr) {
        if (curr) {
            this.accessKeyToTvs = curr;
            this.disabledField = true;
        }
        else {
            this.accessKeyToTvs = '';
            this.disabledField = false;
        }
    }
    onIntegrationTokenStorage(curr) {
        if (curr) {
            this.integrationKeyToTvs = curr;
            this.disabledField = true;
        }
        else {
            this.integrationKeyToTvs = '';
            this.disabledField = false;
        }
    }
    setNewTokenToTvs() {
        this.accessKeyToTvs = '';
        this.integrationKeyToTvs = '';
        this.keyToTvsCheck = false;
        localStorage.removeItem('tokenToTvs');
    }
    checkBtnAction() {
        if (this.disabledField) {
            this.disabledField = false;
            this.setNewTokenToTvs();
        }
        else if (this.statusTokensToTvs) {
            this.toggleDisable(false);
        }
        else {
            this.checkTokensToTvs(this.accessKeyToTvs, this.integrationKeyToTvs);
        }
    }
};
__decorate([
    PropSync('tokenIntegration', { default: '' })
], ToTvsConfigCard.prototype, "integrationKeyToTvs", void 0);
__decorate([
    PropSync('tokenAccess', { default: '' })
], ToTvsConfigCard.prototype, "accessKeyToTvs", void 0);
__decorate([
    Prop({ default: false })
], ToTvsConfigCard.prototype, "statusTokensToTvs", void 0);
__decorate([
    Prop({ default: '' })
], ToTvsConfigCard.prototype, "accessTokenStorage", void 0);
__decorate([
    Prop({ default: '' })
], ToTvsConfigCard.prototype, "integrationTokenStorage", void 0);
__decorate([
    Prop({ default: false })
], ToTvsConfigCard.prototype, "loading", void 0);
__decorate([
    Emit('toggleDisable')
], ToTvsConfigCard.prototype, "toggleDisable", null);
__decorate([
    Emit('checkTokensToTvs')
], ToTvsConfigCard.prototype, "checkTokensToTvs", null);
__decorate([
    Watch('accessTokenStorage')
], ToTvsConfigCard.prototype, "onAccessTokenStorage", null);
__decorate([
    Watch('integrationTokenStorage')
], ToTvsConfigCard.prototype, "onIntegrationTokenStorage", null);
ToTvsConfigCard = __decorate([
    Component({
        name: 'ToTvsConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], ToTvsConfigCard);
export default ToTvsConfigCard;
