var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop, Emit, Watch, } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
import TextClickToCopy from '@/components/TextClickToCopy.vue';
import { getVideosCRM } from '@/shared/utils/Infosets.utils';
let FunilDeVendasConfigCard = class FunilDeVendasConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.openRightDrawer = false;
        this.keyFunilDeVendasCheck = false;
        this.isPwdFunilDeVendas = true;
        this.isCodSellerFunilDeVendas = true;
        this.isCodSalesChannelFunilDeVendas = false;
        this.isCodFunil = true;
        this.openModal = false;
        this.companyFields = {
            fields: {
                'Razão Social': true,
                CNPJ: true,
                Endereço: true,
                Setor: true,
                Decisores: true,
                'Outras Informações': true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: true,
                Comercial: true,
                Financeiro: true,
                Gestão: true,
                Industrial: true,
                Jurídico: true,
                Marketing: true,
                Proprietários: true,
                'Recursos Humanos': true,
                Suprimento: true,
                TI: true,
                Operacional: true,
                Outros: true,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'Posição',
            'Nível de decisão',
            'Departamento',
            'Link do linkedIn',
        ];
    }
    get linkVideo() {
        return getVideosCRM('funildevendas');
    }
    tooltipText() {
        const message = `<li>Exportar até 10.000 empresas sem ou com poucos decisores;</li>
            <li>Possuir permissões de Admin no Funil de Vendas;</li>
            <li>Qualquer plano do Fuil de Vendas oferta integração.</li>
            `;
        return message;
    }
    checkTokenFunilDeVendas(tokenFunilDeVendas, codSeller, codSalesChannel, keyCodFunilDeVendas) {
        return {
            tokenFunilDeVendas,
            codSeller,
            codSalesChannel,
            keyCodFunilDeVendas,
        };
    }
    onTokenStorage(curr) {
        if (curr) {
            this.keyFunilDeVendas = curr;
            this.keyFunilDeVendasCheck = true;
        }
        else {
            this.keyFunilDeVendas = '';
            this.keyFunilDeVendasCheck = false;
        }
    }
    onCodSellerStorage(curr) {
        if (curr) {
            this.keyCodSellerFunilDeVendas = curr;
            this.isCodSellerFunilDeVendas = true;
        }
        else {
            this.keyCodSellerFunilDeVendas = '';
            this.isCodSellerFunilDeVendas = false;
        }
    }
    onCodFunilStorage(curr) {
        if (curr) {
            this.keyCodFunilDeVendas = curr;
            this.isCodFunil = true;
        }
        else {
            this.keyCodFunilDeVendas = '';
            this.isCodFunil = false;
        }
    }
    onCodSallesChannelStorage(curr) {
        if (curr) {
            this.keyCodSalesChannelFunilDeVendas = curr;
            this.isCodSalesChannelFunilDeVendas = true;
        }
        else {
            this.keyCodSalesChannelFunilDeVendas = '';
            this.isCodSalesChannelFunilDeVendas = false;
        }
    }
    setNewTokenFunilDeVendas() {
        this.keyFunilDeVendas = '';
        this.keyCodSalesChannelFunilDeVendas = '';
        this.keyCodSellerFunilDeVendas = '';
        this.keyCodFunilDeVendas = '';
        this.keyFunilDeVendasCheck = false;
        this.isCodSalesChannelFunilDeVendas = false;
        this.isCodSellerFunilDeVendas = false;
        this.isCodFunil = false;
        this.isPwdFunilDeVendas = false;
        localStorage.removeItem('tokenFunilDeVendas');
        localStorage.removeItem('codSellerFunilDeVendas');
        localStorage.removeItem('codSalesChannelFunilDeVendas');
        localStorage.removeItem('keyCodFunilDeVendas');
    }
};
__decorate([
    PropSync('token', { default: '' })
], FunilDeVendasConfigCard.prototype, "keyFunilDeVendas", void 0);
__decorate([
    PropSync('codSeller', { default: '' })
], FunilDeVendasConfigCard.prototype, "keyCodSellerFunilDeVendas", void 0);
__decorate([
    PropSync('codFunil', { default: '' })
], FunilDeVendasConfigCard.prototype, "keyCodFunilDeVendas", void 0);
__decorate([
    PropSync('codSalesChannel', { default: '' })
], FunilDeVendasConfigCard.prototype, "keyCodSalesChannelFunilDeVendas", void 0);
__decorate([
    Prop({ default: false })
], FunilDeVendasConfigCard.prototype, "loading", void 0);
__decorate([
    Prop({ default: '' })
], FunilDeVendasConfigCard.prototype, "tokenStorage", void 0);
__decorate([
    Prop({ default: '' })
], FunilDeVendasConfigCard.prototype, "keyCodFunilDeVendasStorage", void 0);
__decorate([
    Prop({ default: '' })
], FunilDeVendasConfigCard.prototype, "codSellerFunilDeVendasStorage", void 0);
__decorate([
    Prop({ default: '' })
], FunilDeVendasConfigCard.prototype, "codSalesChannelFunilDeVendasStorage", void 0);
__decorate([
    Emit('checkTokenFunilDeVendas')
], FunilDeVendasConfigCard.prototype, "checkTokenFunilDeVendas", null);
__decorate([
    Watch('tokenStorage', { immediate: true })
], FunilDeVendasConfigCard.prototype, "onTokenStorage", null);
__decorate([
    Watch('codSellerFunilDeVendasStorage', { immediate: true })
], FunilDeVendasConfigCard.prototype, "onCodSellerStorage", null);
__decorate([
    Watch('keyCodFunilDeVendasStorage', { immediate: true })
], FunilDeVendasConfigCard.prototype, "onCodFunilStorage", null);
__decorate([
    Watch('codSalesChannelFunilDeVendasStorage', { immediate: true })
], FunilDeVendasConfigCard.prototype, "onCodSallesChannelStorage", null);
FunilDeVendasConfigCard = __decorate([
    Component({
        name: 'FunilDeVendasConfigCard',
        components: {
            Modal,
            Tooltip,
            TextClickToCopy,
        },
    })
], FunilDeVendasConfigCard);
export default FunilDeVendasConfigCard;
