var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { json } from 'overmind';
import FunilDeVendasConfigCard from '../components/FunilDeVendasConfigCard.vue';
let FunilDeVendasContainer = class FunilDeVendasContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.setTokenFunilDeVendas = json(this.stateTokenFunilDeVendas);
        //@ts-ignore
        this.setKeyCodFunilDeVendas = json(this.keyCodFunilDeVendas);
        //@ts-ignore
        this.setCodSellerFunilDeVendas = json(this.stateCodSellerFunilDeVendas);
        //@ts-ignore
        this.setCodSalesChannelFunilDeVendas = json(this.stateCodSalesChannelFunilDeVendas);
        this.tokenStorage = '';
        this.codSellerFunilDeVendasStorage = '';
        this.codSalesChannelFunilDeVendasStorage = '';
        this.keyCodFunilDeVendasStorage = '';
        this.loading = false;
    }
    mounted() {
        this.tokenStorage = localStorage.getItem('tokenFunilDeVendas');
        this.codSellerFunilDeVendasStorage = localStorage.getItem('codSellerFunilDeVendas');
        this.codSalesChannelFunilDeVendasStorage = localStorage.getItem('codSalesChannelFunilDeVendas');
        this.keyCodFunilDeVendasStorage = localStorage.getItem('keyCodFunilDeVendas');
    }
    async checkTokenFunilDeVendas({ tokenFunilDeVendas, codSeller, codSalesChannel, keyCodFunilDeVendas, }) {
        this.loading = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.getTokenFunilDeVendas({
            tokenFunilDeVendas,
            codSeller,
            codSalesChannel,
            keyCodFunilDeVendas,
        });
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }
};
FunilDeVendasContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        stateTokenFunilDeVendas: state.users.tokenFunilDeVendas,
        stateCodSellerFunilDeVendas: state.users.codSellerFunilDeVendas,
        stateCodSalesChannelFunilDeVendas: state.users.codSalesChannelFunilDeVendas,
        keyCodFunilDeVendas: state.users.keyCodFunilDeVendas,
        actions: actions.users,
    }), {
        name: 'FunilDeVendasContainer',
        components: {
            FunilDeVendasConfigCard,
        },
    }))
], FunilDeVendasContainer);
export default FunilDeVendasContainer;
