var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { json } from 'overmind';
import { connect } from '@/overmind';
import { Component, Vue } from 'vue-property-decorator';
import ToTvsConfigCard from '../components/ToTvsConfigCard.vue';
let ToTvsContainer = class ToTvsContainer extends Vue {
    constructor() {
        super(...arguments);
        this.accessTokenStorage = '';
        this.integrationTokenStorage = '';
        this.loading = false;
        //@ts-ignore
        this.setAccessTokenToTvs = json(this.stateAccessTokenToTvs);
        //@ts-ignore
        this.setIntegrationTokenToTvs = json(this.stateIntegrationTokenToTvs);
    }
    mounted() {
        this.accessTokenStorage = localStorage.getItem('accessTokenToTvs');
        this.integrationTokenStorage = localStorage.getItem('integrationTokenToTvs');
    }
    toggleDisable(value) {
        //@ts-ignore TS2349: property inexistent
        this.actions.setStatusTokensToTvs(value);
    }
    async checkTokensToTvs({ accessKeyToTvs, integrationKeyToTvs, }) {
        this.loading = true;
        //@ts-ignore TS2349: property inexistent
        await this.actions.getTokensToTvs({
            accessToken: accessKeyToTvs,
            integrationToken: integrationKeyToTvs,
        });
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }
};
ToTvsContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.users,
        stateAccessTokenToTvs: state.users.accessTokenToTvs,
        stateIntegrationTokenToTvs: state.users.integrationTokenToTvs,
        statusTokensToTvs: state.users.statusTokensToTvs,
    }), {
        name: 'ToTvsContainer',
        components: {
            ToTvsConfigCard,
        },
    }))
], ToTvsContainer);
export default ToTvsContainer;
