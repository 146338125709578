var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
import AccountSubscriptionCard from '@/components/Shop/AccountSubscriptionCard.vue';
import CancelSubscriptionModal from '@/components/Shop/CancelSubscriptionModal.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import LoadingDots from '@/components/LoadingDots.vue';
import { formatDateToBR, formatCurrency } from '@/shared/utils/helpers';
let ManageSubscriptionsComponent = class ManageSubscriptionsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.current_subscription = {};
    }
    formatDate(date) {
        return formatDateToBR(date);
    }
    formatPrice(price) {
        return formatCurrency(price);
    }
    cancelSubscription(superlogicaId) {
        return;
    }
    closeModal() {
        return;
    }
    openModal(current_subscription) {
        this.current_subscription = current_subscription;
        return;
    }
    isUserPLGOrSpeedioUser() {
        // @ts-ignore
        const accountTypeUserLogged = this.$auth.user().accountType;
        // @ts-ignore
        const emailUserLogged = this.$auth.user().email;
        return (!accountTypeUserLogged ||
            accountTypeUserLogged == 'undefined' ||
            accountTypeUserLogged.toLowerCase() == 'free' ||
            emailUserLogged.includes('speedio'));
    }
};
__decorate([
    Prop({ default: () => { } })
], ManageSubscriptionsComponent.prototype, "accountSubscriptions", void 0);
__decorate([
    PropSync('open', { default: true })
], ManageSubscriptionsComponent.prototype, "showModal", void 0);
__decorate([
    Prop({ default: false })
], ManageSubscriptionsComponent.prototype, "loadingCancelButton", void 0);
__decorate([
    Emit('cancelSubscription')
], ManageSubscriptionsComponent.prototype, "cancelSubscription", null);
__decorate([
    Emit('closeModal')
], ManageSubscriptionsComponent.prototype, "closeModal", null);
__decorate([
    Emit('openModal')
], ManageSubscriptionsComponent.prototype, "openModal", null);
ManageSubscriptionsComponent = __decorate([
    Component({
        name: 'ManageSubscriptionsComponent',
        components: {
            AccountSubscriptionCard,
            CancelSubscriptionModal,
            LoadingDots,
            ErrorMessage,
        },
    })
], ManageSubscriptionsComponent);
export default ManageSubscriptionsComponent;
