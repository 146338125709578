var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { json } from 'overmind';
import PloomesConfigCard from '../components/PloomesConfigCard.vue';
let PloomesContainer = class PloomesContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.setTokenPloomes = json(this.stateTokenPloomes);
        this.tokenStorage = '';
        this.loading = false;
    }
    mounted() {
        this.tokenStorage = localStorage.getItem('tokenPloomes');
    }
    async checkTokenPloomes(token) {
        this.loading = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.getTokenPloomes({ token });
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }
};
PloomesContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        stateTokenPloomes: state.users.tokenPloomes,
        actions: actions.users,
    }), {
        name: 'PloomesContainer',
        components: {
            PloomesConfigCard,
        },
    }))
], PloomesContainer);
export default PloomesContainer;
