var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Emit, Prop, Vue, Component } from 'vue-property-decorator';
import IconSpecialFilters from '@/components/Icons/Subscriptions/IconSpecialFilters.vue';
import { formatCurrency } from '@/shared/utils/helpers';
let SubscriptionCard = class SubscriptionCard extends Vue {
    openModal() {
        return true;
    }
    get formatPrice() {
        return formatCurrency(Number(this.price));
    }
};
__decorate([
    Prop({ default: false })
], SubscriptionCard.prototype, "subscriptionHasLoaded", void 0);
__decorate([
    Prop({ default: '' })
], SubscriptionCard.prototype, "title", void 0);
__decorate([
    Prop({ default: '' })
], SubscriptionCard.prototype, "description", void 0);
__decorate([
    Prop({ default: '' })
], SubscriptionCard.prototype, "icon", void 0);
__decorate([
    Prop({ default: '' })
], SubscriptionCard.prototype, "price", void 0);
__decorate([
    Prop({ default: false })
], SubscriptionCard.prototype, "has_demo", void 0);
__decorate([
    Emit('openModal')
], SubscriptionCard.prototype, "openModal", null);
SubscriptionCard = __decorate([
    Component({
        name: 'SubscriptionCard',
        components: {
            IconSpecialFilters,
        },
    })
], SubscriptionCard);
export default SubscriptionCard;
