var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Emit, } from 'vue-property-decorator';
import Modal from './Modal.vue';
let Carousel = class Carousel extends Vue {
    constructor() {
        super(...arguments);
        this.slide = this.images[0] || '';
    }
    closeCarousel() {
        return true;
    }
};
__decorate([
    Prop({ default: () => { } })
], Carousel.prototype, "images", void 0);
__decorate([
    PropSync('openCarousel', { default: true })
], Carousel.prototype, "showCarousel", void 0);
__decorate([
    Emit('closeCarousel')
], Carousel.prototype, "closeCarousel", null);
Carousel = __decorate([
    Component({
        name: 'Carousel',
        components: {
            Modal,
        },
    })
], Carousel);
export default Carousel;
