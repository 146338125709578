var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import AccountInformationPaymentMethod from '@/modules/subscriptions/components/AccountInformation/Details/AccountInformationPaymentMethod.vue';
import PaymentDescription from '@/modules/subscriptions/components/PaymentDescription.vue';
import Checkbox from '@/components/Checkbox.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
import LoadingDots from '@/components/LoadingDots.vue';
import NewModal from '@/components/NewModal.vue';
let PurchaseSubscriptionModal = class PurchaseSubscriptionModal extends Vue {
    constructor() {
        super(...arguments);
        this.continueBtnDisabled = false;
        this.paymentInfo = {
            items: [],
            warning: '',
            description: {
                planName: '',
                value: '',
            },
            prices: [],
            orderSummary: [],
        };
        this.fixed = false;
        this.acceptedTerms = false;
        this.editPaymentInformation = false;
        this.saveTerms = false;
        this.activedMethod = -1;
        this.paymentsMethods = [
            {
                currentMethod: true,
                creditCard: true,
            },
            {
                creditCard: false,
            },
        ];
        this.creditCardData = {
            name: '',
        };
        this.finishRequestToSelectAdditional = false;
    }
    getEditModeInfo(val) {
        this.editPaymentInformation = val;
    }
    acceptTerms() {
        this.fixed = false;
        this.acceptedTerms = true;
    }
    refuseTerms() {
        this.fixed = false;
        this.acceptedTerms = false;
    }
    openTerms() {
        this.fixed = true;
    }
    async beforeMount() {
        this.$emit('disableButton', true);
    }
    selectMethod(index) {
        if (isNaN(index))
            this.activedMethod = -1;
        else
            this.activedMethod = index;
    }
    disableTermsAcceptable(val, old) {
        if (val) {
            this.saveTerms = this.acceptedTerms;
            this.acceptedTerms = false;
        }
        else {
            this.acceptedTerms = this.saveTerms;
        }
    }
    handlerEnableButton(val) {
        this.$emit('disableButton', !val);
    }
    async handleButton() {
        this.$emit('subscriptionNextStep');
    }
    mounted() {
        mixpanelTracking('upgrade: Acessou método de pagamento');
        this.paymentInfo.items = [
            {
                name: 'Recorrência',
                value: 'Mensal',
            },
            {
                name: 'Fidelidade',
                value: 'Nenhuma',
            },
        ];
        if (this.subscription.has_demo) {
            this.paymentInfo.items.push({
                name: 'Primeiro mês',
                value: 'Grátis',
            });
        }
        this.paymentInfo.description.planName = this.subscription.name;
        this.paymentInfo.description.value =
            this.subscription.category == 'app' ? 'Aplicativo' : 'Filtro';
        this.paymentInfo.warning =
            '* A cobrança será feita mensalmente junto com o valor do seu plano';
        this.paymentInfo.orderSummary = [
            {
                name: 'Valor por mês',
                value: +this.subscription.price,
            },
        ];
    }
    async purchaseAdditional() {
        this.continueBtnDisabled = true;
        // @ts-ignore
        const response = await this.shopActions.contractAnAdditional(this.subscription.id);
        if (response)
            this.showModal = false;
        this.continueBtnDisabled = false;
    }
};
__decorate([
    PropSync('open', { default: false })
], PurchaseSubscriptionModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: {} })
], PurchaseSubscriptionModal.prototype, "subscription", void 0);
__decorate([
    Prop({ default: false })
], PurchaseSubscriptionModal.prototype, "loading", void 0);
__decorate([
    Watch('editPaymentInformation')
], PurchaseSubscriptionModal.prototype, "disableTermsAcceptable", null);
__decorate([
    Watch('acceptedTerms')
], PurchaseSubscriptionModal.prototype, "handlerEnableButton", null);
PurchaseSubscriptionModal = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.shop,
        actions: actions.users,
        shopActions: actions.shop,
        infosetsActions: actions.infosets,
        effects: actions.shop,
    }), {
        components: {
            PaymentDescription,
            Checkbox,
            AccountInformationPaymentMethod,
            LoadingDots,
            NewModal,
        },
    }))
], PurchaseSubscriptionModal);
export default PurchaseSubscriptionModal;
