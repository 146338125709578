var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AppStoreComponent from '@/modules/shop/components/Apps/AppStoreComponent.vue';
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
let AppStoreContainer = class AppStoreContainer extends Vue {
    constructor() {
        super(...arguments);
        this.apps = {};
        this.filters = {};
        this.subscriptionHasLoaded = false;
        this.subscriptionHasFailed = false;
    }
    async getSubscriptions() {
        // @ts-ignore
        const result = await this.shopActions.listAvailableAdditionals();
        if (!result.error) {
            this.apps = result.apps;
            this.filters = result.filters;
        }
        else {
            this.subscriptionHasFailed = true;
        }
        this.subscriptionHasLoaded = true;
    }
};
AppStoreContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.shop,
        actions: actions.users,
        shopActions: actions.shop,
        infosetsActions: actions.infosets,
        effects: actions.shop,
    }), {
        name: 'AppStoreContainer',
        components: {
            AppStoreComponent,
        },
    }))
], AppStoreContainer);
export default AppStoreContainer;
