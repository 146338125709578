var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Emit, Prop, Vue, Component } from 'vue-property-decorator';
import IconTriangle from '@/components/Icons/IconTriangle.vue';
import IconSpecialFilters from '@/components/Icons/Subscriptions/IconSpecialFilters.vue';
import { formatCurrency } from '@/shared/utils/helpers';
let SubscriptionModalHeader = class SubscriptionModalHeader extends Vue {
    constructor() {
        super(...arguments);
        this.processing = false;
        this.iconDefault = '<img height="120px" src="https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"/>';
    }
    formatPrice(val) {
        return formatCurrency(val);
    }
    get signInfo() {
        return `Após o teste, <b>${this.formatPrice(Number(this.price))}</b>/mês`;
    }
    purchaseAdditional(id) {
        return id;
    }
    closeModal() {
        return;
    }
    scheduleDemo(title) {
        return { title: title };
    }
};
__decorate([
    Prop({ default: () => { } })
], SubscriptionModalHeader.prototype, "id", void 0);
__decorate([
    Prop({ default: () => { } })
], SubscriptionModalHeader.prototype, "title", void 0);
__decorate([
    Prop({ default: () => { } })
], SubscriptionModalHeader.prototype, "description", void 0);
__decorate([
    Prop({ default: () => { } })
], SubscriptionModalHeader.prototype, "category", void 0);
__decorate([
    Prop({ default: () => { } })
], SubscriptionModalHeader.prototype, "icon", void 0);
__decorate([
    Prop({ default: () => { } })
], SubscriptionModalHeader.prototype, "price", void 0);
__decorate([
    Prop({ default: false })
], SubscriptionModalHeader.prototype, "has_demo", void 0);
__decorate([
    Prop({ default: false })
], SubscriptionModalHeader.prototype, "loading", void 0);
__decorate([
    Emit('purchaseAdditional')
], SubscriptionModalHeader.prototype, "purchaseAdditional", null);
__decorate([
    Emit('closeModal')
], SubscriptionModalHeader.prototype, "closeModal", null);
__decorate([
    Emit('scheduleDemo')
], SubscriptionModalHeader.prototype, "scheduleDemo", null);
SubscriptionModalHeader = __decorate([
    Component({
        name: 'SubscriptionModalHeader',
        components: {
            IconTriangle,
            IconSpecialFilters,
        },
    })
], SubscriptionModalHeader);
export default SubscriptionModalHeader;
