var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Emit, Prop, Vue, Component } from 'vue-property-decorator';
import IconSpecialFilters from '@/components/Icons/Subscriptions/IconSpecialFilters.vue';
let AccountSubscriptionCard = class AccountSubscriptionCard extends Vue {
    openModal() {
        return true;
    }
};
__decorate([
    Prop({ default: '' })
], AccountSubscriptionCard.prototype, "title", void 0);
__decorate([
    Prop({ default: '' })
], AccountSubscriptionCard.prototype, "description", void 0);
__decorate([
    Prop({ default: '' })
], AccountSubscriptionCard.prototype, "icon", void 0);
__decorate([
    Prop({ default: '' })
], AccountSubscriptionCard.prototype, "price", void 0);
__decorate([
    Prop({ default: '' })
], AccountSubscriptionCard.prototype, "date", void 0);
__decorate([
    Prop({ default: '' })
], AccountSubscriptionCard.prototype, "cancellation_date", void 0);
__decorate([
    Prop({ default: '' })
], AccountSubscriptionCard.prototype, "status", void 0);
__decorate([
    Emit('openModal')
], AccountSubscriptionCard.prototype, "openModal", null);
AccountSubscriptionCard = __decorate([
    Component({
        name: 'AccountSubscriptionCard',
        components: {
            IconSpecialFilters,
        },
    })
], AccountSubscriptionCard);
export default AccountSubscriptionCard;
