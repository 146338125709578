var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { json } from 'overmind';
import PiperunConfigCard from '../components/PiperunConfigCard.vue';
let PiperunContainer = class PiperunContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.setTokenPiperun = json(this.stateTokenPiperun);
        this.tokenStorage = '';
        this.loading = false;
    }
    mounted() {
        this.tokenStorage = localStorage.getItem('tokenPiperun');
    }
    async checkTokenPiperun(token) {
        this.loading = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.getTokenPiperun({ token });
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }
};
PiperunContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        stateTokenPiperun: state.users.tokenPiperun,
        actions: actions.users,
    }), {
        name: 'PiperunContainer',
        components: {
            PiperunConfigCard,
        },
    }))
], PiperunContainer);
export default PiperunContainer;
