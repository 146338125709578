var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop, Emit, Watch, } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
import { getVideosCRM } from '@/shared/utils/Infosets.utils';
let PiperunConfigCard = class PiperunConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.keyPiperunCheck = false;
        this.isPwdPiperun = true;
        this.openModal = false;
        this.companyFields = {
            fields: {
                'Razão Social/Nome Fantasia': true,
                CNPJ: true,
                Endereço: true,
                Situação: true,
                'Dados Corporativos': true,
                'Nome do Administrador': true,
                'Capital Social': true,
                Setor: true,
                'Cnae Primário': true,
                'Cnae Secundário': true,
                'Data de Abertura': true,
                'Faixa de Faturamento da Unidade CNPJ': true,
                'Faixa de Faturamento da Empresa': true,
                'Faixa de Funcionários da Unidade CNPJ': true,
                'Faixa de Funcionarios da Empresa': true,
                'Natureza Jurídica': true,
                Administrador: true,
                Facebook: true,
                Website: true,
                'Todos os Emails': true,
                'Todos os Telefones': true,
                'Telefones Válidos': true,
                Decisores: true,
                'Emails Principais': true,
                'Telefones Principais': true,
                Origem: true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'Empresa',
            'E-mail',
            'Posição',
            'Nível',
            'Departamento',
            'Link do linkedIn',
        ];
    }
    get linkVideo() {
        return getVideosCRM('piperun');
    }
    tooltipText() {
        const message = `
            <li>Exportar até 10.000 empresas sem ou com poucos decisores;</li>
            <li>Possuir permissões habilitadas para integração com o Piperun;</li>
            <li>Verificar se o plano da sua conta seja Pro, Flow ou Performace.</li>
            `;
        return message;
    }
    checkTokenPiperun(tokenPiperun) {
        return tokenPiperun;
    }
    onTokenStorage(curr) {
        if (curr) {
            this.keyPiperun = curr;
            this.keyPiperunCheck = true;
        }
        else {
            this.keyPiperun = '';
            this.keyPiperunCheck = false;
        }
    }
    setNewTokenPiperun() {
        this.keyPiperun = '';
        this.keyPiperunCheck = false;
        localStorage.removeItem('tokenPiperun');
    }
};
__decorate([
    PropSync('token', { default: '' })
], PiperunConfigCard.prototype, "keyPiperun", void 0);
__decorate([
    Prop({ default: false })
], PiperunConfigCard.prototype, "loading", void 0);
__decorate([
    Prop({ default: '' })
], PiperunConfigCard.prototype, "tokenStorage", void 0);
__decorate([
    Emit('checkTokenPiperun')
], PiperunConfigCard.prototype, "checkTokenPiperun", null);
__decorate([
    Watch('tokenStorage')
], PiperunConfigCard.prototype, "onTokenStorage", null);
PiperunConfigCard = __decorate([
    Component({
        name: 'PiperunConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], PiperunConfigCard);
export default PiperunConfigCard;
