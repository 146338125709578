var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Emit, Prop, PropSync, Vue, Component } from 'vue-property-decorator';
import Modal from '../Modal.vue';
import PurchaseSubscriptionModal from './PurchaseSubscriptionModal.vue';
import SubscriptionModalHeader from './Modal/SubscriptionModalHeader.vue';
import SubscriptionModalMain from './Modal/SubscriptionModalMain.vue';
import Carousel from '@/components/Carousel.vue';
import { connect } from '@/overmind';
let SubscriptionModal = class SubscriptionModal extends Vue {
    constructor() {
        super(...arguments);
        this.showCarousel = false;
        this.openPurchaseModal = false;
        this.loading_subscription = false;
    }
    openCarousel() {
        this.showCarousel = true;
    }
    closeCarousel() {
        this.showCarousel = false;
    }
    async purchaseAdditional(_return, name) {
        this.loading_subscription = true;
        //@ts-ignore
        const res = await this.shopActions.canContractSubscription(name);
        if (res.status == 200)
            this.openPurchaseModal = true;
        this.loading_subscription = false;
    }
    async scheduleDemo(title) {
        //@ts-ignore
        await this.actions.scheduledDemo(title);
    }
    closeModal() {
        return;
    }
};
__decorate([
    Prop({ default: () => { } })
], SubscriptionModal.prototype, "subscription", void 0);
__decorate([
    PropSync('open', { default: true })
], SubscriptionModal.prototype, "showModal", void 0);
__decorate([
    Emit('closeModal')
], SubscriptionModal.prototype, "closeModal", null);
SubscriptionModal = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.shop,
        actions: actions.users,
        shopActions: actions.shop,
        infosetsActions: actions.infosets,
        effects: actions.shop,
    }), {
        name: 'SubscriptionModal',
        components: {
            Modal,
            SubscriptionModalHeader,
            SubscriptionModalMain,
            Carousel,
            PurchaseSubscriptionModal,
        },
    }))
], SubscriptionModal);
export default SubscriptionModal;
