var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ManageSubscriptionsComponent from '../components/ManageSubscriptionsComponent.vue';
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
let ManageSubscriptions = class ManageSubscriptions extends Vue {
    constructor() {
        super(...arguments);
        this.accountSubscriptions = '';
        this.showModal = false;
        this.loadingCancelButton = false;
    }
    async mounted() {
        await this.refreshAccountSubscription();
    }
    async refreshAccountSubscription() {
        this.accountSubscriptions =
            // @ts-ignore
            await this.actions.getAccountSubscriptionsWithPrices();
    }
    async cancelSubscription(id) {
        this.loadingCancelButton = true;
        // @ts-ignore
        await this.actions.cancelAccountSubscription(id);
        this.loadingCancelButton = false;
        this.showModal = false;
        // @ts-ignore
        await this.refreshAccountSubscription();
    }
    openModal() {
        this.showModal = true;
    }
    closeModal() {
        this.showModal = false;
    }
};
ManageSubscriptions = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.shop,
        infosetsActions: actions.infosets,
    }), {
        name: 'ManageSubscriptions',
        components: {
            ManageSubscriptionsComponent,
        },
    }))
], ManageSubscriptions);
export default ManageSubscriptions;
