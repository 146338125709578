var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import MeetimeConfigCard from '../components/MeetimeConfigCard.vue';
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { json } from 'overmind';
let MeetimeContainer = class MeetimeContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.setTokenMeetime = json(this.stateTokenMeetime);
        this.tokenStorage = '';
        this.loading = false;
    }
    mounted() {
        this.tokenStorage = localStorage.getItem('tokenMeetime');
    }
    async checkTokenMeetime(token) {
        //@ts-ignore TS2349: property inexistent
        await this.actions.getTokenMeetime({ token });
        //@ts-ignore
        // await this.actions.saveCrmAutoplay({ crm: 'meetime', token: token })
        //@ts-ignore
        // await this.actions.getCrmAutoplay('meetime')
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }
};
MeetimeContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.users,
        stateTokenMeetime: state.users.tokenMeetime,
    }), {
        name: 'MeetimeContainer',
        components: {
            MeetimeConfigCard,
        },
    }))
], MeetimeContainer);
export default MeetimeContainer;
