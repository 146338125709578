var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SubscriptionCard from '@/components/Shop/SubscriptionCard.vue';
import SubscriptionModal from '@/components/Shop/SubscriptionModal.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import LoadingDots from '@/components/LoadingDots.vue';
let AppStoreComponent = class AppStoreComponent extends Vue {
    constructor() {
        super(...arguments);
        this.showModal = false;
        this.showAnnotationConfig = true;
        this.showRecordConfig = false;
        this.showMailerWeb = false;
        this.exportOracle = false;
        this.showSpeedMail = false;
        this.subscription = {};
        this.tab = 'aplicativos';
        this.splitterModel = 100;
        this.shop_apps = [];
        this.special_filters = [];
    }
    openModal(subscription) {
        this.subscription = subscription;
        this.showModal = true;
    }
    onTabChanged() {
        this.closeModal();
    }
    closeModal() {
        this.showModal = false;
    }
    mounted() {
        this.$emit('updateSubscriptions', true);
    }
};
__decorate([
    Prop({ default: () => { } })
], AppStoreComponent.prototype, "currentUser", void 0);
__decorate([
    Prop({ default: false })
], AppStoreComponent.prototype, "subscriptionHasLoaded", void 0);
__decorate([
    Prop({ default: false })
], AppStoreComponent.prototype, "subscriptionHasFailed", void 0);
__decorate([
    Prop({ default: [{}] })
], AppStoreComponent.prototype, "apps", void 0);
__decorate([
    Prop({ default: [{}] })
], AppStoreComponent.prototype, "filters", void 0);
__decorate([
    Watch('tab')
], AppStoreComponent.prototype, "onTabChanged", null);
AppStoreComponent = __decorate([
    Component({
        name: 'AppStoreComponent',
        components: {
            SubscriptionCard,
            SubscriptionModal,
            LoadingDots,
            ErrorMessage,
        },
    })
], AppStoreComponent);
export default AppStoreComponent;
