import { render, staticRenderFns } from "./PurchaseSubscriptionModal.vue?vue&type=template&id=842a882a&scoped=true&"
import script from "./PurchaseSubscriptionModal.vue?vue&type=script&lang=ts&"
export * from "./PurchaseSubscriptionModal.vue?vue&type=script&lang=ts&"
import style0 from "./PurchaseSubscriptionModal.vue?vue&type=style&index=0&id=842a882a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "842a882a",
  null
  
)

export default component.exports