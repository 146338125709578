import { render, staticRenderFns } from "./CancelSubscriptionModalMain.vue?vue&type=template&id=45ed94c1&scoped=true&"
import script from "./CancelSubscriptionModalMain.vue?vue&type=script&lang=ts&"
export * from "./CancelSubscriptionModalMain.vue?vue&type=script&lang=ts&"
import style0 from "./CancelSubscriptionModalMain.vue?vue&type=style&index=0&id=45ed94c1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ed94c1",
  null
  
)

export default component.exports